
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import { ThemeProvider } from 'vue3-styled-components'
import { Theme } from './style/globalStyles.js'

/**
 * TS 函数 返回值类型
 */
export default {
  components: { ThemeProvider },
  data (): unknown {
    return {
      Theme,
      locale: zhCN
    }
  }
}
