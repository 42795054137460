/* eslint-disable */
import { useCreateStore } from '@/store'

/**
 * 解析 url
 * @constructor
 */
export const parseUrl = () => {
  const urlParamsAll = new URL(window.location.href)
  // get pathname
  const result = {
    pathname: urlParamsAll.pathname
  }

  // get param
  const url = urlParamsAll.search

  if (url.indexOf('?') !== -1) {
    const str = url.substr(1)
    const paramArr = str.split('&')
    for (let i = 0; i < paramArr.length; i++) {
      const itemArr = paramArr[i].split('=')
      result[itemArr[0]] = unescape(itemArr[1]) // 解码 Url
    }
  }

  return result
}

/**
 * 公共下拉筛选 处理接口返回的数据格式
 * @param collect 下拉框 options
 * @param array 接口返回数据
 * @param key 对应 collect 的 key
 */
export const handleSelectApiData = (collect, array, key) => {
  collect.forEach(object => {
    if (object.type === key) {
      array.forEach(item => {
        const query = object.query.replace('@#@', item.item_id)
        object.options.push({
          name: item.title,
          value: item.title,
          label: item.title,
          item_id: item.item_id,
          group: object.group,
          type: object.type,
          query
        })
      })
    }
  })
}

/**
 * 修复下拉框滚动，导致整个页面滚动的组件问题
 * @param isNoScroll
 * @returns {boolean}
 */
export const disabledScroll = (isNoScroll) => {
  const body = document.getElementsByTagName('body')[0]

  if (!isNoScroll) {
    body.style.overflow = 'hidden'
    body.style.height = '100%'
  } else {
    body.style.overflow = ''
    body.style.height = ''
  }

  return !isNoScroll
}

/**
 * 图像转 Base64
 */
export const getBase64Image = img => {
  const canvas = document.createElement('canvas')
  canvas.width = img.width
  canvas.height = img.height
  const ctx = canvas.getContext('2d')
  ctx.drawImage(img, 0, 0, img.width, img.height)
  const ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase()
  return canvas.toDataURL('image/' + ext)
}

/**
 * Base64 字符串转 二进制
 */
export const dataURLtoBlob = dataUrl => {
  const arr = dataUrl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bStr = atob(arr[1])
  let n = bStr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bStr.charCodeAt(n)
  }
  return new Blob([u8arr], {
    type: mime
  })
}

/**
 * 指定获取 VueX 筛选项中的某一项
 * @param queryArray
 * @param string
 * @return Object/Boolean
 */
export const getFilterQuery = (queryArray, string) => {
  // 返回可解构对象
  if (string === 'all') {
    const result = {}
    queryArray.forEach(item => {
      result[item.type] = item
    })
    return result
  }

  return queryArray.find(item => (item.type === string ? item : false))
}

/**
 * Set Storage Group
 * @param string
 * @returns {`${suffix}-${string}`}
 */
export const handleStorageGroup = string => {
  // get suffix
  const store = useCreateStore()
  let suffix
  if (store.state.multiStation === 'task') {
    suffix = 'Task'
  } else {
    suffix = 'History'
  }

  return `${suffix}-${string}`
}

export function debounce(fn, wait) {
  let timer = null;
  return function () {
    let context = this,
      args = arguments;
    if (timer) { // 如果此时存在定时器的话，则取消之前的定时器重新计时
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      fn.apply(context, args); // 改变this的指向，让其指向调用这个方法的对象
    }, wait | 1000)
  }
}
