<template>
  <Container>
    <LoginInfo>
      <span class="time">{{ data.time }}</span>
      <span class="username">{{ data.name }}</span>
    </LoginInfo>
  </Container>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import { useCreateStore } from '@/store'
import dayjs from 'dayjs'
import { Container, LoginInfo } from './StyledHeader'

export default defineComponent({
  name: 'Header',
  components: { Container, LoginInfo },
  setup () {
    const store = useCreateStore()
    const data = reactive({
      name: store.state.user.name,
      time: dayjs().format('YYYY年MM月DD日')
    })

    return { data }
  }
})

</script>

<style scoped>

</style>
