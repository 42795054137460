import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "product-table" }
const _hoisted_2 = { class: "table-thead" }
const _hoisted_3 = { class: "table-tbody" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, null, {
    default: _withCtx(() => [
      _createElementVNode("table", _hoisted_1, [
        _createElementVNode("thead", _hoisted_2, [
          _renderSlot(_ctx.$slots, "table-th")
        ]),
        _createElementVNode("tbody", _hoisted_3, [
          _renderSlot(_ctx.$slots, "table-td")
        ])
      ])
    ]),
    _: 3
  }))
}