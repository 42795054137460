import styled from 'vue3-styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin-top: .2rem;
  border-radius: 3px;
  padding: .4rem .2rem;

  .title {
    color: ${props => props.theme.themeColor};
    font-family: ${props => props.theme.fontFamily};
    font-size: .37rem;
    text-align: center;
  }

  .machine-info {
    font-size: .34rem;
    margin: .4rem 0;

    .point {
      color: #6C6C6C;
      font-weight: 500;
      .number {
        color: #C5002C;
      }
    }

    .machine-list {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      .machine-item {
        width: 7rem;
        height: .6rem;
        line-height: .6rem;
        margin-top: .2rem;
        border-radius: 5px;
        text-align: center;
        background-color: #F0F0F0;
      }
    }
  }
  
  .count-time {
    margin-top: .5rem;
    color: #9A9A9A;
    font-family: ${props => props.theme.fontFamily};
    font-size: .33rem;
    text-align: center;
  }
`
