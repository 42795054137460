/* eslint-disable */
import httpRequest from '../../../util/Request'

// 获取自己的机器列表
export function apiGetTaskList (userId, machineIds) {
  return httpRequest({
    url: '/api/index/billlist',
    method: 'post',
    data: {
      user_id: userId,
      machine_id: machineIds
    }
  })
}

// 获取补货推荐单/机器上架单
export function apiGetStockProduct (machineId, userId) {
  return httpRequest({
    url: '/api/index/billlistMachine',
    method: 'post',
    data: { machine_id: machineId, user_id: userId }
  })
}

// 待领取-无调拨-直接确认
export function apiConfirmReceive (machine_id, take, transfer) {
  return httpRequest({
    url: '/api/index/confirmReceive',
    method: 'post',
    data: { machine_id, take, transfer }
  })
}

// 待执行-确认上架
export function apiConfirmOnline (machineId) {
  return httpRequest({
    url: '/api/index/confirmPut',
    method: 'post',
    data: { machine_id: machineId }
  })
}

// 补货中-我要完成
export function apiWantToFinish (user_id, machine_id) {
  return httpRequest({
    url: '/api/index/confirmFinish',
    method: 'post',
    data: { user_id, machine_id }
  })
}
