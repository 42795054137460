<template>
  <Container>
    <div class="title">带货单产品汇总清单</div>
    <div class="machine-info">
      <div class="point">包含一下选中机器（<span class="number">{{ data.activeNum }}台</span>）:</div>
      <div class="machine-list">
        <div class="machine-item" v-for="(item, index) in data.machine" :key="index">{{ item.machineName }}</div>
      </div>
    </div>
    <SimpleTable>
      <template v-slot:table-th>
        <tr>
          <th>产品系列名称</th>
          <th>合计数量</th>
        </tr>
      </template>
      <template v-slot:table-td>
        <tr v-for="item in data.product" :key="item">
          <td>{{ item.goods_name }}</td>
          <td>{{ item.number }}</td>
        </tr>
      </template>
    </SimpleTable>
    <!-- 统计时间 -->
    <div class="count-time">统计时间：{{ countTime }}</div>
  </Container>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { Container } from './StyledProductList'
import SimpleTable from './SimpleTable'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'ProductList',
  components: { Container, SimpleTable },
  props: { data: Object },
  setup () {
    const countTime = ref(dayjs().format('YYYY-MM-DD HH:mm'))

    return { countTime }
  }
})

</script>

<style scoped>

</style>
