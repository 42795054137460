import styled from 'vue3-styled-components'

export const Container = styled.div`
  height: 1.1rem;
`

export const LoginInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  width: 7rem;
  height: .75rem;
  font-size: .4rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
  background-color: #5C5EBE;
  box-shadow: 3px 3px 6px 1px rgb(81 82 157);
  border-radius: .5rem
`
