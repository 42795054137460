<template>
  <Container class="container">
    <Header/>
    <TopWrapper>
      <TaskStatus>
        <TaskItemStatus primary>
          <label class="label">今日已完成</label>
          <div class="amount">{{ data.finishCount }}</div>
        </TaskItemStatus>
        <TaskItemStatus>
          <label class="label">今日进行中</label>
          <div class="amount">{{ data.doing }}</div>
        </TaskItemStatus>
      </TaskStatus>
      <HaveTo @click="handleMustGo">
        <img class="icon" :src="require('../../assets/work.png')" alt="">
        <div class="hint">
          <div class="title">📢点击此处前往设置今日必去机器~</div>
          <div class="ps">ps：系统将根据您的设置重新计算最新补货计划</div>
        </div>
      </HaveTo>
      <!-- 管理维护 -->
      <router-link to="/stock-list" class="link">
        <ManageMaintain>维护机器备货情况</ManageMaintain>
      </router-link>
    </TopWrapper>
    <!-- 表格 -->
    <TaskTable :list="data.taskList" @handleProductTotal="handleProductTotal"/>
    <!-- 产品汇总清单 -->
    <ProductList :data="data.summaryList" v-show="data.isShowRadio"/>
  </Container>
</template>

<script>
/* eslint-disable */
import { defineComponent, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { SmileOutlined, DownOutlined } from '@ant-design/icons-vue'
import Header from '../../components/Header/Header.vue'
import TaskTable from '../../components/Table/TaskTable.vue'
import ProductList from '../../components/Table/ProductList.vue'
import {
  Container, TopWrapper,
  TaskStatus, TaskItemStatus,
  HaveTo, ManageMaintain, Table,
} from './StyledTaskList'
import { apiGetTaskList } from '../../request/apis/Task/TaskListApi'
import { message } from 'ant-design-vue'

export default defineComponent({
  name: 'TaskList',
  components: {
    Container,
    TopWrapper,
    TaskStatus,
    Header,
    TaskItemStatus,
    HaveTo,
    ManageMaintain,
    Table,
    SmileOutlined,
    DownOutlined,
    TaskTable,
    ProductList
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const data = reactive({
      taskList: {},
      mustGo: false,
      userId: store.state.user.user_id,
      doing: 0,
      finishCount: 0,
      summaryList: {
        machine: [],
        product: [],
        activeNum: 0
      },
      isShowRadio: false
    })
    onMounted(() => {
      apiGetTaskList(data.userId, store.state.haveToId).then((res) => {
        data.taskList = res.data.data.list
        data.doing = res.data.data.doing
        data.finishCount = res.data.data.finish_count
        data.mustGo = res.data.data.mustgo
      })
    })

    const handleMustGo = () => {
      if (data.mustGo) {
        router.push({ name: 'HaveTo' })
      } else {
        message.info('当前有未完成的任务')
      }
    }

    const handleProductTotal = (listItem, isRemove, isShow) => {
      const item = JSON.parse(JSON.stringify(listItem))
      data.isShowRadio = isShow
      // 判断是删除还是添加
      if (!isRemove) {
        data.summaryList.machine.push({
          machine_id: item.machine_id,
          machineName: item.machine_name,
        })
        data.summaryList.activeNum += 1
        // 判断是否已存在产品系列合并数量
        item.take.forEach(object => {
          if (JSON.stringify(data.summaryList.product) === '[]') {
            data.summaryList.product.push(object)
          } else {
            const findIndex = data.summaryList.product.findIndex((product) => {
              return object.goods_code === product.goods_code
            })

            if (findIndex === -1) {
              data.summaryList.product.push(object)
            } else {
              data.summaryList.product[findIndex].number += object.number
            }
          }
        })
      } else {
        data.summaryList.machine.forEach((object, index) => {
          if (item.machine_id === object.machine_id) {
            data.summaryList.machine.splice(index, 1)
          }
        })
        data.summaryList.activeNum -= 1
        // 删除合计数量
        item.take.forEach(object => {
          const findIndex = data.summaryList.product.findIndex((product) => {
            return object.goods_code === product.goods_code
          })
          if (findIndex === -1) {
            console.error('未查询到元素')
          } else {
            data.summaryList.product[findIndex].number -= object.number
            if (data.summaryList.product[findIndex].number === 0) {
              data.summaryList.product.splice(findIndex, 1)
            }
          }
        })
      }
    }

    return { data, handleMustGo, handleProductTotal };
  }
})

</script>

<style scoped>
.link {
  color: #6B6DFA;
  text-decoration: none;
  outline: none;
}
</style>
