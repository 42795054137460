import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!
  const _component_theme_provider = _resolveComponent("theme-provider")!

  return (_openBlock(), _createBlock(_component_theme_provider, { theme: $data.Theme }, {
    default: _withCtx(() => [
      _createVNode(_component_a_config_provider, { locale: $data.locale }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }, 8, ["locale"])
    ]),
    _: 1
  }, 8, ["theme"]))
}