/* eslint-disable */
import httpRequest from '../../../util/Request'

// 获取用户信息
export function apiGetUserInfo () {
  return httpRequest({
    url: 'https://api.huoban.com/v2/ticket/parse',
    method: 'get'
  })
}
