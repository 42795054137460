import styled from 'vue3-styled-components'

export const Container = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  font-size: .3rem;
  text-align: center;

  .product-table {
    width: 100%;

    .table-thead {
      height: .8rem;
      line-height: .8rem;
      color: ${props => props.theme.themeColor};
      font-weight: bold;

      th {
        padding: .03rem .2rem;
        border: 1px solid #E0E0E0;
      }
    }

    .table-tbody {
      height: .8rem;
      line-height: .8rem;

      td {
        height: .8rem;
        padding: 0 .3rem;
        border: 1px solid #E0E0E0;
      }
    }
  }
`
