import { createRouter, createWebHashHistory } from 'vue-router'
import { parseUrl } from '@/util/Helpers'
import { useCreateStore } from '@/store'
import TaskList from '../views/Task/TaskList.vue'
import { apiGetUserInfo } from '@/request/apis/User/UserApi'

const routes = [
  {
    path: '/',
    name: 'TaskList',
    component: TaskList
  },
  {
    path: '/have-to',
    name: 'HaveTo',
    component: () => import('../views/HaveTo/HaveTo.vue')
  },
  {
    path: '/stock-list',
    name: 'StockList',
    component: () => import('../views/Stock/StockList.vue')
  },
  {
    path: '/stock-machine-detail/:item',
    name: 'StockMachineDetail',
    component: () => import('../views/Stock/StockMachineDetail.vue')
  },
  {
    path: '/task-operate/:machine_id',
    name: 'TaskOperate',
    component: () => import('../views/Task/TaskOperate.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  /**
   * @type {Store}
   */
  const store = useCreateStore()
  const urlParam = JSON.parse(window.sessionStorage.getItem('urlParam'))

  // TAG 防止无限重定向，需要添加无需过滤的路由
  if (urlParam === null) {
    const param = parseUrl()
    if ('ticket' in param) {
      window.sessionStorage.setItem('urlParam', JSON.stringify(param))
      store.dispatch('login', await login())
      store.dispatch('setMultiStation', param.multiStation)
    } else {
      // TODO 完善信息错误页面传参问题
      console.error('未找到token', JSON.stringify(to))
    }
  } else {
    // 更新 window.sessionStorage/用户状态/多端状态
    await isExistsAllowToken(to, urlParam)
  }

  // 检测是否是 门店任务端
  if (store.state.multiStation === 'task' && to.path !== '/task' && to.name !== 'Detail') {
    return next({ name: 'TaskList' })
  }

  next()
})

const isExistsAllowToken = async (to, urlParam) => {
  /**
   * @type {Store}
   */
  const store = useCreateStore()
  const param = parseUrl()
  if ('ticket' in param) {
    window.sessionStorage.setItem('urlParam', JSON.stringify(param))
    store.dispatch('setMultiStation', param.multiStation) // 设置多端参数
  } else {
    store.dispatch('setMultiStation', urlParam.multiStation) // 设置多端参数
  }

  store.dispatch('login', await login()) // 登陆设置 VueX 用户信息
}

const login = () => {
  return apiGetUserInfo().then(res => {
    if (res.status === 200) {
      return res.data.user
    }
  })
}

export default router
