import styled from 'vue3-styled-components'

const colorProps = { primary: Boolean }

export const Container = styled.div`
  display: block;
  height: 100%;
`

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 4.2rem;
`

export const TaskStatus = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: .37rem;
  color: rgba(255, 255, 255, 0.9);
`

export const TaskItemStatus = styled('div', colorProps)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 3.6rem;
  
  .label { font-family: ${props => props.theme.fontFamily}; }

  .amount {
    width: 1.4rem;
    height: .8rem;
    line-height: .8rem;
    text-align: center;
    font-size: .3rem;
    color: ${props => props.primary ? '#6A6BC5' : '#FE121A'};
    background: #fff;
    border-radius: .4rem;
  }
`

export const HaveTo = styled.div`
  display: flex;
  height: 1.6rem;
  color: #6B6DFA;
  background-color: #fff;
  border-radius: 1rem;
  margin: .2rem 0 .3rem;
  padding: .05rem;
  
  .icon {
    object-fit: contain;
  }
  
  .hint {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: .3rem;
    
    .title {
      font-size: .35rem;
      font-family: ${props => props.theme.fontFamily};
      margin-bottom: .15rem;
    }

    .ps {
      color: #999;
      font-size: .3rem;
      font-weight: 400;
    }
  }
`

export const ManageMaintain = styled.div`
  height: 1rem;
  line-height: 1rem;
  text-align: center;
  color: #6B6DFA;
  font-family: ${props => props.theme.fontFamily};
  font-size: .35rem;
  background-color: #fff;
  border-radius: 1rem;
`

export const Table = styled.div`
  //width: 100%;
  overflow-x: auto;
  //background-color: rgba(255, 1, 100, 0.3);
`
