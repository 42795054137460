import axios from 'axios'
import { parseUrl } from './Helpers'

// 创建一个 axios 实例
const service = axios.create({
  baseURL: 'http://haidingtest.popmart.zixuni.com', // 所有的请求地址前缀部分
  timeout: 60000, // 请求超时时间毫秒
  withCredentials: false, // 异步请求携带cookie
  headers: {
    // 设置后端需要的传参类型
    'Content-Type': 'application/json'
    // 'X-Requested-With': 'XMLHttpRequest'
  }
})

// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    let ticket = null
    if (parseUrl.ticket === undefined) {
      ticket = JSON.parse(window.sessionStorage.getItem('urlParam')).ticket
    }
    config.headers['X-Huoban-Ticket'] = ticket

    return config
  },

  function (error) {
    // 对请求错误做些什么
    console.error('对请求错误做些什么', error)
    return Promise.reject(error)
  }
)

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    let dataAxios

    // 2xx 范围内的状态码都会触发该函数。
    if (response.status === 200) {
      // 对响应数据做点什么
      dataAxios = response
    } else {
      dataAxios = response
    }

    return dataAxios
  },

  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    return Promise.reject(error.response)
  }
)

export default service
