import { injectGlobal } from 'vue3-styled-components'
import ZhunYuanGBT from './ZhunYuanGBT.ttf'
import CuYuanGBT from './CuYuanGBT.ttf'

// 获取屏幕宽度
const windowWidth = document.documentElement.clientWidth || document.body.clientWidth

injectGlobal`
  @font-face {
    font-family: 'ZhunYuanGBT';
    src: url('${ZhunYuanGBT}');
  }

  @font-face {
    font-family: 'CuYuanGBT';
    src: url('${CuYuanGBT}');
  }

  html {
    font-size: ${windowWidth / 10}px !important;
  }

  // 移动端禁止横向移动
  html, body {
    position: absolute;
    left: 0;
    top: 0;
    overflow: scroll;
    overflow-y: auto;
  }
`
