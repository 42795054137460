// 主题参数
export const Theme = {
  themeColor: '#6A6BC5',
  fontFamily: 'CuYuanGBT',
  primary: 'pink',

  // 主体公共数据
  centerData: {
    width: '9.5rem',
    widthNoUnit: 9.5, // 宽度无单位(有些要动态计算)
    bgc: '#fff',
    fontSize: '.27rem'
  }
}
