
import { defineComponent } from 'vue'
import { Container } from './StyledSimpleTable'

export default defineComponent({
  name: 'SimpleTable',
  components: { Container },
  setup () {
    return {}
  }
})

