<template>
  <div class="task-table">
    <table class="task-product-table">
      <tr class="task-table-th">
        <th style="width: 15%; background-color: #5C90F9"></th>
        <th style="width: 10%">推荐优先级</th>
        <th style="width: 30%">当前补货状态</th>
        <th style="width: 40%">机器名称</th>
        <th style="width: 5%">货架情况</th>
      </tr>
      <tbody class="task-table-tbody">
        <tr class="task-table-td" v-for="(item, index) in list" :key="index">
          <td>
            <img v-if="data.radioActive.includes(index)" class="radio-img" :src="require('../../assets/u77.png')" @click.stop="handleRadio(item, index)">
            <img v-else class="radio-img" :src="require('../../assets/u76.png')" @click.stop="handleRadio(item, index)">
          </td>
          <td>{{ index + 1 }}</td>
          <td>
            <a-tag :color="data.color[item.status]" @click="handleTask(item.machine_id)">{{ data.word[item.status] }}</a-tag>
            <!--<router-link :to="`/task-operate/${ item.machine_id }`">{{ data.word[item.status] }}</router-link>-->
          </td>
          <td>{{ item.machine_name }}</td>
          <td><login-outlined :style="{fontSize: '.5rem', color: '#F66D4E', cursor: 'pointer'}"/></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { LoginOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  name: 'TaskTable',
  components: { LoginOutlined },
  props: { list: Array },
  setup (props, { emit }) {
    const router = useRouter()
    const data = reactive({
      word: {
        '-2': '删除',
        '-1': '取消',
        0: '待领取',
        1: '执行中',
        2: '补货中',
        3: '已完成'
      },
      color: {
        '-2': '#D4D4D4',
        '-1': '#707070',
        0: '#FE2A2E',
        1: '#FF8D20',
        2: '#098E36',
        3: '#2E8FC3'
      },
      radioActive: []
    })

    const handleRadio = (item, index) => {
      const isRemove = data.radioActive.includes(index)
      if (isRemove) {
        // 移除
        data.radioActive.splice(data.radioActive.indexOf(index), 1)
      } else {
        data.radioActive.push(index)
      }
      const isShow = data.radioActive.length !== 0
      emit('handleProductTotal', item, isRemove, isShow)
    }

    const handleTask = machineId => {
      router.push({ name: 'TaskOperate', params: { machine_id: machineId } })
    }

    return { data, handleRadio, handleTask }
  }
})

</script>

<style scoped lang="scss">
.task-table {
  background-color: #fff;
  box-sizing: border-box;
  border-width: .12rem;
  border-style: solid;
  border-color: white;
  width:100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space:nowrap;
  border-radius: 3px;

  .task-product-table {
    width: 100%;
    font-size: .36rem;
    text-align: center;

    .task-table-th {
      height: .65rem;
      line-height: .65rem;
      background-color: #5C5EBE;
      color: white;

      th {
        padding: 0 .2rem;
        border-right: 1px solid #888ada;
      }
    }

    .task-table-tbody {
      white-space: nowrap;

      .task-table-td {
        height: .9rem;
        line-height: .9rem;

        td {
          vertical-align: middle; // 图片上下居中
          padding: 0 .3rem;
          border-bottom: 1px solid white;
          .radio-img {
            display: block;
            width: .5rem;
          }
          &:first-child {
            padding: 0 .1rem;
          }
        }
        // 偶数行
        &:nth-child(even) {
          background-color: #E0E0E0;
        }
        // 奇数行
        &:nth-child(odd) {
          background-color: #F0F0F0;
        }
      }
    }
  }
}
</style>
