/* eslint-disable */
import { createStore } from 'vuex'
import { apiGetUserInfo } from '@/request/apis/User/UserApi.js'

const store = createStore({
  state: {
    user: {},
    multiStation: '',
    haveToId: []
  },
  mutations: {
    multiStation (state, target) {
      state.multiStation = target
    },
    setUserInfo (state, user) {
      state.user = user
    },
    renewHaveToId (state, array) {
      state.haveToId = array
    }
  },
  actions: {
    setMultiStation (store, target) {
      store.commit('multiStation', target)
    },
    login (store, user) {
      store.commit('setUserInfo', user)
    },
    setHaveToId (store, array) {
      store.commit('renewHaveToId', array)
    }
  },
  modules: {
  }
})

// 将store 导出去
export const useCreateStore = () => store

export default store
